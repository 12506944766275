/* Nosotros.module.css */
.mainContainerNos{
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: auto;
  width: 100%;
}
/* Estilos base */
.nosotrosSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Cambiado a columna para apilar el contenido */
  padding: 20px;
  margin: 0 auto; /* Ajuste del margen para centrar verticalmente */
  padding-top: 5%;
  width: 70%;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  overflow: hidden;
}

.content {
  text-align: center;
  z-index: 1;
}

.content h1 {
  color: black;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  position: relative;
}

.content h1::after {
  content: '';
  display: block;
  width: 20%;
  height: 2px;
  background-color: black;
  margin: 10px auto; /* Ajusta la distancia entre la palabra y el subrayado */
}

.content p {
  display: flex;
  justify-content: center;
  margin: 5%; /* Puedes ajustar estos valores según sea necesario */
  font-size: 17px; /* Puedes ajustar el tamaño del texto */
  line-height: 1.5; /* Ajusta la altura de línea para mejorar la legibilidad */
  text-align: center;
}

.scrollDown {
  margin-top: 20px;
}

.scrollDown a {
  font-size: 24px;
  color: rgb(0, 0, 0);
  text-decoration: none;
  display: block;
  margin-top: 10px;
}

.empleadoContainer {
  display: flex;
  flex-wrap: wrap; /* Permite que las tarjetas se envuelvan a la siguiente línea */
  justify-content: center;
  margin-top: 20px;
  text-align: center;

}

.nombreCompleto{
  font-weight: bold;
  font-size: 18px;

}
.cargo {
  font-size: 16px;
  color: rgb(36, 36, 36);
}
.cedulaA{
  font-size: 14px;
  color: rgb(49, 49, 49);
}
.cedulaB{
  font-size: 14px;
  color: rgb(59, 59, 59);
}

.empleadoItem {
  width: 16vw; /* Ancho de cada tarjeta de empleado */
  margin: 3%; /* Espaciado entre las tarjetas */
  text-align: center;

}

.empleadoItem img {
  width: 100%; /* Hace que la imagen ocupe el 100% del ancho de la tarjeta */
  height: auto;
  border-radius: 50%;
  margin-bottom: 10px;
}

/* Agrega estas clases para las animaciones */
.animated {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Estilos para pantallas medianas */
@media only screen and (max-width: 768px) {
  .mainContainerNos {
    width: 100%;
    margin-left: 28px;

  }

  .nosotrosSection {
    width: 70%; /* Ajusta el ancho del contenedor en pantallas pequeñas */
  }

  .empleadoItem {
    width: 44%; /* Ajustar el ancho de cada tarjeta en pantallas medianas */
  }
}
/* Estilos para pantallas pequeñas como dispositivos móviles */
@media only screen and (max-width: 480px) {
  .mainContainerNos {
    width: 100%;
    margin-left: 14px;
  }

  .nosotrosSection {
    width: 80%; /* Ajusta el ancho del contenedor en pantallas pequeñas */
  }

}
