/* ObrasEnConstruccion.module.css */

.container {
  display: flex;
  flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(85vh - 150px);
    width: 90%;
    margin: 0 auto;

}

.title {
  color: rgb(203, 203, 203);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding: 10px;
  margin-top: 5%;
  letter-spacing: 2px;
  position: relative;

}

.title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 15%;
  height: 2px; /* Grosor de la línea */
  background-color: rgb(203, 203, 203); /* Color de la línea */
  margin-top: 3px; /* Ajusta la separación vertical de la línea */
}

.obrasEnConstruccionContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin:  0 auto;
    padding: 20px;

}

.link {
    text-decoration: none;
}

.btnVolver {
    display: flex;
    align-items: center;
    margin-top: 2vh; /* Add space between Ver todas las obras button and Volver button */
    background-color: transparent;
    transition: transform 0.3s ease; /* Agrega una transición suave para el efecto hover */
    font-size: 20px;
    text-transform: lowercase;
    letter-spacing: 2px;
  }
  
  .btnVolver:hover {
    transform: scale(1.3); /* Escala el botón al 110% del tamaño original al hacer hover */
    background-color: transparent;
  }
  

.footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 150px;
    margin: 0 auto;
    width: 100%; 

  }
  @media only screen and (max-width: 768px) {
    .footer{
        margin-top: 20%;
    }
    .title{
      margin-top: 10vh;
    }


  }
  @media only screen and (max-width: 480px) {
    .footer{
        margin-top: 20%;
    }
    .title{
      font-size: 14px;
    }


  }