.muralismosContainer {
  text-align: left;
  padding: 20px;
  background-color: whitesmoke;
  margin: 5% 20%; /* Ajuste del margen para dispositivos más pequeños */
  position: relative; /* Añadir position: relative al contenedor principal */
}


.title {
  font-size: 36px;
  margin-left: 10px; /* Reducción del espaciado a la izquierda */
  border-bottom: 1px solid black;
  width: 70%; /* Ajuste del ancho del título para dispositivos más pequeños */
  letter-spacing: 2px;
  text-transform: uppercase;
}

.socialIcons {
  display: flex;
  justify-content: flex-end; /* Alinea los elementos a la derecha */
}

.socialIcons a {
  margin-right: 10px;
  color: rgb(0, 0, 0);
  font-size: 40px; /* Reducción del tamaño de los íconos sociales */
  width: auto; /* Ajuste del ancho para dispositivos más pequeños */
}

.socialIcons a:hover {
  color: rgb(112, 112, 112);
}

.muralesList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.muralT {
  text-transform: uppercase;
  font-size: 12px;
}

.muralItem {
  margin: 10px; /* Reducción del margen */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
}

.imagenContainer {
  margin-top: 10px;
}

.imagen {
  max-width: 100%;
  border-radius: 8px;
}

.link {
  text-decoration: none;
}

.linkToTop {
  position: absolute;
  top: 10px; /* Ajusta según sea necesario para la posición vertical */
  right: 10px; /* Ajusta según sea necesario para la posición horizontal */
  z-index: 999; /* Ajusta según sea necesario para que el botón esté encima de otros elementos */
}



.btnVolver {
  color: whitesmoke;
  background-color: black;
  border: none;
  padding: 10px;
  border-radius: 2px;
  cursor: pointer;
}
.btnVolver:hover{
  background-color: rgb(44, 44, 44);
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilos para dispositivos más pequeños */
@media only screen and (max-width: 768px) {
  .muralismosContainer{
    margin: 10vh 5%; /* Ajuste del margen para dispositivos más pequeños */
  }
  .title {
    font-size: 28px;
    width: 90%;
  }

  .socialIcons a {
    font-size: 30px;
  }

  .muralItem {
    margin: 5px;
  }
}
