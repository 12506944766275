/* Estilos originales */
.footer {
  display: flex;
  justify-content: center;
}

.containerF {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas iguales */
  gap: 20px; /* Espacio entre las imágenes */
  background-color: rgb(115, 115, 115);
  margin: 5% 20%;
  padding: 30px;
  border-radius: 20px;
}

.containerF p {
  grid-column: span 2; /* Ocupa el espacio de ambas columnas */
  text-align: center;
  font-size: 1.1rem;
  color: rgb(243, 243, 243);
  padding: 20px;
  margin: 0 auto;
}

.containerF img {
  width: 100%; /* Ocupa el 100% del ancho del contenedor */
  height: auto; /* Mantén la proporción original de la imagen */
  border-radius: 8px; /* Agrega bordes redondeados si lo deseas */
}

.link {
  text-decoration: none;
}

.btnVolver {
  display: flex;
  align-items: center;
  margin-bottom: 3vh; /* Add space between Ver todas las obras button and Volver button */
  background-color: transparent;
  transition: transform 0.3s ease; /* Agrega una transición suave para el efecto hover */
  font-size: 20px;
  text-transform: lowercase;
  letter-spacing: 2px;
}

.btnVolver:hover {
  transform: scale(1.3); /* Escala el botón al 110% del tamaño original al hacer hover */
  background-color: transparent;
}

/* Estilos adicionales para hacerlo responsive */
@media only screen and (max-width: 768px) {
  .containerF {
    margin: 10vh 5%;
  }

  .containerF img {
    max-width: 100%; /* Hacer que las imágenes ocupen todo el ancho disponible */
  }
}
@media only screen and (max-width: 480px) {
  .containerF {
    margin: 10vh 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Cambiado a columna para apilar el contenido */
  }
  .containerF p{
    font-size: 1rem;
  }

  .containerF img {
    max-width: 100%; /* Hacer que las imágenes ocupen todo el ancho disponible */
  }
}
