.listaDeObras {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;


}

.obras-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

}

.obras-list {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

}

.obras-list-item {
  
  background-color: #f8f8f8;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  flex: 0 0 calc(30% - 20px); 
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  color: rgb(8, 8, 8);
  text-decoration: overline;
}

.obras-list-item:hover {
  transform: scale(1.05);
}

.obras-list-item h3 {
  font-size: 16px;
  margin-bottom: 8px;
  color: #333;
}

.imagenes {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.loading-message {
  font-size: 18px;
  color: #555;
  margin-top: 20px;
}

/* Agrega estilos adicionales según sea necesario */
.deleteButton {
  background-color: rgb(251, 90, 90);
}

.deleteButton:hover {
  background-color: rgb(246, 59, 59);
}

.editButton {
  background-color: rgb(75, 172, 56);
}

.editButton:hover {
  background-color: rgb(42, 166, 17);
}

/* Estilos para pantallas menores o iguales a 768px */

@media (max-width: 768px) {
  .obras-list-item {
    flex: 0 0 calc(100% - 20px);
  }
}
@media (max-width: 480px) {
  .obras-list{
    width: 90vw;
  }
}
