/* ContractorCarousel.module.css */

.container {
  width: 73%; /* Ajusta el ancho según tus preferencias */
  overflow: hidden;
  margin: 20px auto;
  background-color: white;

}
.title{
  color: black;
  text-align: center;
  font-size: 24px;
  margin-bottom: -40px;
  text-transform: uppercase;
  padding-top: 2%;
  letter-spacing: 2px;
  font-weight: bold;
  position: relative;
}
.title::after{
  content: '';
  display: block;
  width: 30%;
  height: 2px;
  background-color: black;
  margin: 10px auto; /* Ajusta la distancia entre la palabra y el subrayado */
}

.slider {
  margin: 5vw auto;
}

.slider img {
  width: auto;
  height: 60px;
  border: 1px solid #ddd; /* Borde ligero para separar las imágenes */
  border-radius: 5px; /* Bordes redondeados */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave */
  transition: transform 0.3s ease-in-out;
}

.slider img:hover {
  transform: scale(1.1); /* Efecto de escala al pasar el ratón */
}


/* Estilos para pantallas inferiores o iguales a 1024px */
@media (max-width: 1024px) {
  .container{
    width: 74%;
  }
  .title{
    font-size: 14px;
  }

  .slider{
    margin: 7vw auto;
  }

}


/* Estilos para pantallas inferiores o iguales a 768px */
@media (max-width: 768px) {
  .container{
    width: 75%;
  }
  .title{
    font-size: 14px;
  }

  .slider{
    margin: 7vw auto;
  }
  .slider img{
    width: 100%;
    height: auto;
  }

}

  
@media (max-width: 480px) {
  .container{
    width: 90%;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: 10px auto;
    border: 1px solid white;
  }

  .slider{
    margin: 15vw 0 0 0;
  }
  .slider img{
    width: 100%;
    height: auto;
  }
}