.obraCard {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s;
  width: 32%;
}

.obraCard:hover {
  transform: scale(1.05);
}

.link {
  display: block;
  text-decoration: none;
  color: inherit;
}

.image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  opacity: 1;
  transition: opacity 0.7s ease;
}

.image:hover {
  opacity: 0.2;
}

.centeredText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 10px;
}

.centeredText p {
  margin: 0;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 0.9;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.link {
  text-decoration: none;
}

button { 
  margin: 10px auto;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #3f3f3f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #272727;
}

/* Añadido estilo para mejorar la legibilidad del texto en pantallas pequeñas */
@media only screen and (max-width: 768px) {
  .centeredText {
    padding: 5px;
    font-size: 12px;
  }

  button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
  .obraCard {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s;
    width: 40%;
  }
}

/* Añadido estilo para mejorar la legibilidad del texto en pantallas pequeñas */
@media only screen and (max-width: 480px) {
  .centeredText {
    padding: 5px;
    font-size: 10px;
  }

  button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
  .obraCard {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s;
    width: 45%;
  }
}
