/* ObrasEnConstruccion.module.css */

.container {
  display: flex;
  flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(85vh - 150px);
    width: 90%;
    margin: 0 auto;

}

.title {
  color: rgb(203, 203, 203);
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding: 10px;
  margin-top: 5%;
  letter-spacing: 2px;
  position: relative;
  text-align: center;

}

.title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 15%;
  height: 2px; /* Grosor de la línea */
  background-color: rgb(203, 203, 203); /* Color de la línea */
  margin-top: 3px; /* Ajusta la separación vertical de la línea */
}

.obrasTerminadasContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin:  0 auto;
  padding: 20px;
}

.footerContainerOT {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 150px;
  margin: 0 auto;
  width: 100%; 
}




.link {
  text-decoration: none;
}

.btnContainer{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
/* Estilos base para los botones */
.button {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  padding: 10px 20px;
  border: 2px solid #000;
  border-radius: 5px;
  font-weight: bold;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

/* Estilos específicos para cada botón */
.polyfilmbtn {
  display: flex;
  align-items: center;

  background-color: transparent;
  transition: transform 0.3s ease; /* Agrega una transición suave para el efecto hover */
  font-size: 20px;
  text-transform: lowercase;
  letter-spacing: 2px;

  margin-bottom: 0;
}

.polyfilmbtn:hover {
  transform: scale(1.3); /* Escala el botón al 110% del tamaño original al hacer hover */
  background-color: transparent;
}

.btnVolver {
  display: flex;
  align-items: center;
  background-color: transparent;
  transition: transform 0.3s ease; /* Agrega una transición suave para el efecto hover */
  font-size: 20px;
  text-transform: lowercase;
  letter-spacing: 2px;
  margin-bottom: 0;
}

.btnVolver:hover {
  transform: scale(1.3); /* Escala el botón al 110% del tamaño original al hacer hover */
  background-color: transparent;
}

/* Efecto de hover para los botones */
.button:hover {
  background-color: #000;
  color: #fff;
}

/* Estilos para dispositivos más pequeños */
@media only screen and (max-width: 768px) {
  .footerContainerOT{
      margin-top: 20%;
  }
  .title{
    margin-top: 10vh;
  }


}
@media only screen and (max-width: 480px) {
  .footer{
      margin-top: 20%;
  }
  .title{
    font-size: 14px;
  }


}
