.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 80px;
  width: 80%;
}

.item {
  position: relative;
  overflow: hidden;
}

.item img {
  width: 100%;
  height: 500px;
  display: block;
  transition: transform 0.3s ease; /* Transición para suavizar el zoom */
}

.item img:hover {
  transform: scale(1.02);
}

.title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
}

  
/* Media Queries */
@media only screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    padding: 60px; /* Reducir el padding */
    gap: 20px;
  }

  .item img {
      height: auto; /* Reducir la altura de las imágenes para dispositivos móviles */
  }
}

@media only screen and (max-width: 480px) {
  .container {
      padding: 40px; /* Reducir el padding */

  }

  .item img {
    width: 100%;
      height: 250px; /* Reducir aún más la altura de las imágenes para dispositivos móviles */

  }
}