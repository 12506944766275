/* Header.module.css */

/* Estilos base */
.header {
  background-color: transparent;
  margin: 20px;
  width: 95vw;
  height: 9vh;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: black;
  z-index: 1000;
  width: 95%;
  padding: 10px;
  margin: 0 auto;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-size: 24px;
  font-weight: bold;
}

.logo img {
  width: 20%;
  height: auto;
}

.navLinks {
  display: flex;
}

.navLinks a {
  text-decoration: none;
  padding-right: 10px;
  color: rgb(254, 254, 254);
  border-right: 1px solid rgb(255, 255, 255);
  margin: 20px;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.navLinks a:hover {
  color: #675302;
  transform: scale(1.1);
}

/* Estilos para pantallas menores o iguales a 768px */

@media (max-width: 768px) {
  .header {
    width: 92vw;
    height: 2vh;
  }
  .nav {
    position: relative;
  }

  .menuContainer {
    overflow-y: auto; /* Agregar desplazamiento vertical */
  }
  .navLinks {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.7);
    z-index: 1001;
    margin: 0 auto;
  }

  .navLinks a {
    margin-bottom: 10px;
    border-right: 0;
  }

  .nav.openMenu .navLinks {
    display: flex;
    height: 100%;
    overflow-y: auto;
  }

  .menuIcon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: fixed;
    top: 1%;
    right: 1%;
    transform: translate(-50%);
    z-index: 1002;
  }

  .bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 6px 0;
  }

  /* Nuevos estilos para las imágenes en el menú desplegado */
  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px; /* Ajusta el margen inferior según sea necesario */
  }

  .tabContent {
    position: relative;
    box-shadow: 0px 4px 10px rgba(0, 1, 1, 0.4);
  }

  .tab img {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 5px; /* Ajusta el margen inferior según sea necesario */
  }

  .tabContent span {
    position: absolute;
    bottom: 40%; /* Ajusta el posicionamiento vertical según sea necesario */
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 1; /* Asegura que el texto esté por encima de la superposición */
    font-weight: 500;
    letter-spacing: 2px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Color de superposición semitransparente */
    z-index: 0; /* Asegura que la superposición esté detrás de la imagen y el texto */
  }

  /* Clase para evitar el desplazamiento de la página */
  .noScroll {
    overflow: hidden;
  }
}
