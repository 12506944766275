/* Estilos base */
.footerContainer {
  background-color: #000000; /* Ajusta el color de fondo según tu diseño */
  color: #fff; /* Ajusta el color del texto según tu diseño */
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 85%;
  margin: 0 auto;
  padding: 5px;
}

.contactInfo {
  display: flex;
  gap: 20px;
  
}

.contactInfo div {
  display: flex;
  align-items: center;
}

.contactInfo p {
  margin: 0;
  margin-left: 10px;
}
.contactInfo a {
  margin: 0;
  margin-left: 10px;
  color: inherit; /* Utiliza el color del texto heredado */
  text-decoration: none; /* Elimina el subrayado predeterminado */
  cursor: pointer;
}

.contactInfo a:hover {
  text-decoration: underline;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logoContainer img {
  width: 100px; /* Ajusta el ancho del logo según tus preferencias */
  height: auto;
  margin-right: 10px; /* Ajusta el margen entre el logo y el párrafo */
}

.logoContainer p {
  margin: 0;
  color: #fff; /* Ajusta el color del texto según tu diseño */
}



/* Estilos para pantallas pequeñas */
@media only screen and (max-width: 1108px) {
  .footerContainer {
    flex-direction: column; /* Cambia la dirección a columna en pantallas pequeñas */
    align-items: center; /* Alinea los elementos al principio en pantallas pequeñas */
    text-align: center;
    gap: 20px;
  }

  .contactInfo,
  .logoContainer {
    display: flex;
    flex-direction: column; /* Alinea verticalmente el logo y el párrafo en pantallas más pequeñas */
    align-items: center; 
  }

}
@media only screen and (max-width: 420px){
    .contactTel{
      font-size: 14px;
    }
  .footerContainer{
    font-size: 15px;
    width: 100%;
    padding: 20px;
  }
}
