/* Estilos base */
.exploreContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  min-height: calc(88vh - 150px); /* Resta el espacio del pie de página */
}

.locationContainer {
  position: relative;
  width: 100%; /* Cambiado el ancho a 100% para ocupar todo el ancho del contenedor padre */
  max-width: 500px; /* Ancho máximo para evitar que sea demasiado grande en pantallas grandes */
  height: 350px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  border-radius: 30px;
  overflow: hidden;
  margin: 20px;
  text-align: center;
}

.locationTitle {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  letter-spacing: 5px;
}

.image {
  width: 100%;
  height: auto;
  opacity: 1;
  transition: opacity 0.7s ease;
}

.image:hover {
  opacity: 0.2;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Alinea el contenido en la parte inferior */
  height: 150px; /* Ocupa el 100% de la altura del viewport */
  margin-top: auto; /* Empuja el contenido hacia abajo */

}

/* Estilos para pantallas pequeñas */
@media only screen and (max-width: 1100px) {
  .footer{
    margin-top: 5vh;
  }
}
/* Estilos para pantallas pequeñas */
@media only screen and (max-width: 768px) {
  .exploreContainer{
    margin-top: 10%;
  }
  .locationContainer {
    max-width: 500px; /* Hace que el contenedor sea del 100% en pantallas pequeñas */
    height: auto;
  }
  
  .locationTitle{
    font-size: 16px;
  }

  .footer{
    margin-top: 10vh;
  }
}
