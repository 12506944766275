.container {
    background-color: black;
    width: 90%;
    padding: 40px;
  display: flex;
  flex-direction: column;
    margin: 0 auto;
  }

  .encabezado{
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    border-left: 10px solid white;
    padding-left: 10px;
  }
  
  .title {
    color: white;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 22px;
    padding-left: 20px;
  }
  
  .serviciosContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;

  }
  
  .servicioCard {
    background-color: black;
    color: white;
    padding: 20px;
    width: calc(100% - 20px); /* Espacio para 4 tarjetas con margen de 20px entre ellas */
    box-shadow: 0 0 20px rgba(107, 107, 107, 0.3); /* Ajuste del box shadow */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  
  .icon {
    margin-bottom: 0px; /* Espacio entre el icono y el título */
    font-size: 50px;
  }
  
  .content {
    text-align: center; /* Centrar el texto */
  }
  
  .servicioTitle {
    font-weight: bold;
    margin-bottom: 0px;
  }
  
  .servicioDescription {
    margin-bottom: 0;
  }
  /* Media Queries */
@media only screen and (max-width: 768px) {
  .container {
    padding: 0;
    margin: 0 auto;
  width: 80%;


}
.serviciosContainer{
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

}
.servicioCard {
  margin: 0 auto;
    width: calc(90% - 20px); /* Espacio para 1 tarjeta con margen de 20px */
}


@media only screen and (max-width: 480px) {
  .container {
      padding: 0;
      margin: 0 auto;
    width: 80%;


  }
  .serviciosContainer{
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
    align-items: center;

  }
  .servicioCard {
    margin: 0 auto;

      width: calc(90% - 20px); /* Espacio para 1 tarjeta con margen de 20px */
  }
}
}