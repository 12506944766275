/* frontend/components/EmployeeForm.module.css */
.container {
    max-width: 600px;
    margin: 0 auto;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0 auto;
    justify-content: center;

  }
  
  .form label {
    display: block;
    margin-bottom: 10px;
    color: black;
  }
  
  .form input {
    width: 100%;
    padding: 8px;
    margin-top: 4px;
    margin-bottom: 16px;
    box-sizing: border-box;
    color: black;
    border: 1px solid gray;
  }

  .imagePreview {
    margin-top: 20px;
  }
  
  .previewImage {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
  
  .form button {
    background-color: #0074cc;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .form button:hover {
    background-color: #005496;
  }
  