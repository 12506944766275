.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .form {
    /* Estilos adicionales según sea necesario */
    max-width: 600px;
    width: 100%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #ffffff;
  }
  
  .title {
    text-align: center;
  }
  
  .form label {
    display: block;
    margin-bottom: 10px;
    color: black;

  
  }
  
  .inputContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .form input {
    width: 100%;
    margin-top: 4px;

    box-sizing: border-box;
    color: black;
    border: 1px solid gray;
  }
  
  .imagePreview {
    margin-top: 20px;
    max-height: 300px; /* Tamaño máximo de la sección de previsualización */
    overflow: auto; /* Agrega una barra de desplazamiento si es necesario */
  }
  
  .previewImage {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .alert {
    background-color: #4caf50;
    color: #fff;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .alert button {
    background-color: #45a049;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  /* Form.module.css */
.select-field {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
    background-color: #fff;
    color: #333;
  }
  .publicBtn{
    cursor: pointer;
  }