.detailContainer {
  margin: 5% auto;
  padding: 20px;
  background-color: rgb(203, 203, 203);
  border-radius: 20px;
  display: flex;
  align-items: center; /* Alinea verticalmente los elementos en el centro */
  justify-content: space-between;
  width: 70%;
  min-height: calc(74vh - 150px);
}

.title {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

.slider {
  margin: 2vw;
  width: 60%;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;

}

.image {
  width: 100%;
  height: 50vh;
  max-height: 600px;
  border-radius: 8px;
  object-fit: cover;
}

.dataContainer {
  padding: 10px;
  flex-grow: 1;
  text-align: left; /* Ajusta el texto a la izquierda para evitar centrado vertical */
}

.dataContainer p {
  margin: 10px 0;
}

.link {
  text-decoration: none;
}

.btnVolver {
  color: whitesmoke;
  background-color: black;
}
.btnVolver:hover{
  background-color: #333;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 150px;
    margin: 0 auto;
    width: 100%;

}

/* Estilos para dispositivos más pequeños */
@media only screen and (max-width: 768px) {
  .detailContainer {
    width: 70%;
    flex-direction: column;
    margin-top: 10vh;
  }

  .slider {
    width: 100%;
    margin-bottom: 20px;
  }

  .dataContainer {
    margin-left: 0;
  }
  .footer{
    margin-top: 10vh;
  }
}
