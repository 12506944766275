/* Inicio.module.css */

/* Estilos para pantallas grandes */
.inicioSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  overflow: hidden;
}

.primerContenedor{
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerContainerIn{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7%;
  width: 100%;
}

.main{
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.backgroundImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.backgroundImage img {
  width: 45%;
  height: auto;
}

.content {
  text-align: center;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;
}

.socialIcons a {
  margin-right: 10px;
  color: rgb(255, 255, 255);
  font-size: 30px;
}

.socialIcons a:hover {
  color: rgb(112, 112, 112);
}

.scrollDown {
  margin-top: 20px;
}

.scrollDown a {
  font-size: 30px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  display: block;
  margin-top: 10px;
}

.scrollDown a:hover {
  color: rgb(62, 62, 62);
}

.containerLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.containerLoading p {
  font-size: 14px;
}

.containerLoading img {
  height: auto;
  width: 5vw;
}



/* Estilos para pantallas medianas */
@media only screen and (max-width: 768px) {
  .main{

    justify-content: center;
    height: 450px;
  }
  .backgroundImage{
    height: auto;
  }
  .backgroundImage img {
    width: 80%;

  }
}

@media only screen and (max-width: 480px) {
  .main{

    height: 600px;

  }

  .backgroundImage{
    height: 500px;

  }
  .backgroundImage img {
    width: 90%;
  }
}

