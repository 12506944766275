
.contactPageContainer{
  min-height: calc(90vh - 150px);
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 5vh;
}
.contactoContainer {
  width: 73%;
  margin: 0 auto;
  padding: 2vw;
  background-color: rgb(0, 0, 0);
  display: flex;
  align-items: center;

}

.titleC {
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 24px;
  margin-bottom: 0px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  position: relative;
}
.mapContainer {
    float: left;
    width: 50%;
    box-sizing: border-box; /* Asegura que el borde se incluya en el ancho total */
    padding: 2vw;
  }

  .popupContent {
    text-align: center;
    padding: 10px;
  }
  
  .popupLogo {
    width: 50px;
    border-radius: 50%;
  }
  
  .popupText {
    font-size: 16px;
    margin-top: 5px;
    color: #333; /* Cambia el color del texto según tu preferencia */
  }

  .address {
    text-align: left;
    font-size: 14px;
    color: #b7b7b7; /* Ajusta el color del texto */
}

  
  .formContainer {
    float: right;
    width: 49%;
    box-sizing: border-box; /* Asegura que el borde se incluya en el ancho total */
    margin-top: 20px;
  }
  

/* Estilos adicionales para el formulario (añade según sea necesario) */
.formContainer form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px; /* Ancho máximo del formulario */
    margin: 0 auto;
  }
  
  .formContainer label {
    margin-bottom: 8px;
    color: #fff;
  }
  
  .formContainer input,
  .formContainer textarea {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 8px;
  }
  
  .formContainer textarea {
    resize: vertical; /* Permite la redimensión vertical del área de texto */
    padding: 20px;
    margin-top: 8px;
  }
  
  .formContainer button {
    background-color: #373737;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .formContainer button:hover {
    background-color: #212121;
    color: rgb(255, 255, 255);
  }
  
  .footerContainerCP{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 150px;
    margin: 0 auto;
    width: 100%;
    }


/* Estilos para pantallas inferiores o iguales a 768px */
@media (max-width: 1100px) {
  .footerContainerCP{
    margin-top: 10vh;
  }
}
/* Estilos para pantallas inferiores o iguales a 768px */
@media (max-width: 768px) {
  .footerContainerCP{
    margin-top: 10vh;
  }
  .contactoContainer{
    flex-direction: column;
    padding: 5vw;
  }
  .mapContainer,
  .formContainer {
    width: 100%;
    margin: 0;
  }

  .mapContainer {
    float: none;
    margin-bottom: 20px;
  }

  .formContainer {
    float: none;
    margin-top: 20px;
  }

  .titleC {
    font-size: 20px;
  }
}
/* Estilos para pantallas inferiores o iguales a 480px */
@media (max-width: 480px) {
  .contactPageContainer{
    width: 100%;
  }

  .contactoContainer{
    padding: 0;
    width: 95%;
  }
}