/* CuartoContenedor.module.css */

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px auto;
    width: 90%;
  }
  
  .infoContainer {
    flex: 0 0 30%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center; /* Alinea el contenido al centro */
  }
  
  .title {
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 10px; /* Agrega un pequeño margen inferior al título */
  }
  
  .subtitle {
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px; /* Agrega un pequeño margen inferior al subtítulo */
  }
  
  .text {
    color: white;
    font-size: 16px;
    line-height: 1.5; /* Reduzco un poco el espacio entre líneas */
    margin-bottom: 10px; /* Agrega un pequeño margen inferior al texto */
  }
  
  .rightContainer {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    align-items: center;
    justify-content: center;
  }
  
  .compareSlide {
    width: calc(100% - 0px); /* Ajusta el ancho para que quepan dos en una fila con espacio entre ellos */
    max-width: 400px; /* Define el ancho máximo de las imágenes */
    max-height: 210px; /* Define la altura máxima de las imágenes */
    border-radius: 10px;
    object-position: bottom;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
.react-compare-slider-image {
    width: 100%;
    height: auto;
    object-fit: cover; /* Escala la imagen para que se ajuste al contenedor */
    object-position: center; /* Centra la imagen horizontalmente */
  }
  
  @media (max-width: 768px) {
    .container{
      flex-direction: column;

    }


    .rightContainer{
      justify-content: center;
      align-items: center;
      
    }
    .compareSlide {
      width: 100%; /* En pantallas más pequeñas, mostrar una tarjeta por fila */
      margin-right: 0; /* Elimina el margen derecho en pantallas pequeñas */
    }
  }
  