/* Admin.module.css */

.mainContainer {
  margin: 10vh auto;
  padding: 20px;
  background-color: #f4f4f4;
  width: 75vw;
}

.title {
  color: #333;
  text-align: center;
  text-decoration: overline;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #182127;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 8px;
}

.menuDropdown {
  margin-top: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #696d70;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
}

.contentSection {
  margin: 0;
}

.formCreateContainer {
  margin-top: 15px;
}

.mainFormContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.form-container {
  max-width: 30%;
  width: 100%;
  margin: 15px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.form-label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
}

.form-input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-button {
  background-color: #3498db;
  color: #fff;
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.form-error {
  color: #ff0000;
  margin-top: 10px;
  font-size: 14px;
}

.forgot-password-link,
.back-to-login-link {
  cursor: pointer;
  color: #3498db;
  margin-top: 10px;
}

.forgot-password-link:hover,
.back-to-login-link:hover {
  text-decoration: underline;
  color: #2c3e50;
}

/* Estilos para pantallas menores o iguales a 768px */

@media (max-width: 768px) {
  .mainContainer {
    margin-top: 10vh;
  }

  .title {
    padding: 20px;
  }

  .mainFormContainer {
    height: auto;
    padding: 15px;
    margin-top: 15vh;
  }

  .form-container {
    max-width: 70%;
  }

  .form-input {
    padding: 10px;
  }

  .form-button {
    padding: 12px;
    margin: 10px;
  }

  .menuDropdown {
    margin-top: 15px;
    padding: 10px;
    font-size: 14px;
  }

  .contentSection {
    margin: 5%;
  }

  .formCreateContainer {
    margin-top: 15px;
  }
}

@media (max-width: 480px) {
  .mainContainer {
    margin-top: 5vh;
  }

  .formCreateContainer {
    margin-top: 15px;
  }
}
